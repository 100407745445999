import { HTTP } from '.';
import moment from 'moment';
import buildQuery from '@/helpers/url';

export default new class ReportPublicLinkService {
  constructor() {}

  getVenueReportPublicLink(venueId) {
    return HTTP.get(
      '/v1/venues/' + venueId + '/get_last_public_url');
  }

  generateVenueReportPublicLink(venueId) {
    return HTTP.post(
      '/v1/venues/' + venueId + '/generate_public_url');
  }

  getData(report, token) {
    return HTTP.get(
      '/v1/venues/' + report + '/' + token + '/venue_performance_public_data');
  }

  async paginateRecentContacts(page) {
    let params = {
      page: page || 1,
    };

    let data = await HTTP.get('/v1/venues/' + report + '/' + token + '/recent-contacts?' + buildQuery(params));
    return data;
  }

  async paginateRecentContacts(report, token, page) {
    let params = {
      page: page || 1,
    };

    let data = await HTTP.get('/v1/venues/' + report + '/' + token + '/recent-contacts?' + buildQuery(params));
    return data;
  }

  async paginateRecentSiteVisits(report, token, page) {
    let params = {
      page: page || 1,
    };

    let data = await HTTP.get('/v1/venues/' + report + '/' + token + '/recent-site-visits?' + buildQuery(params));
    return data;
  }

  async paginateRecentSales(report, token, page) {
    let params = {
      page: page || 1,
    };

    let data = await HTTP.get('/v1/venues/' + report + '/' + token + '/recent-sales?' + buildQuery(params));
    return data;
  }

  shareUrlToEmail(report, token, email) {
    return HTTP.post(
      '/v1/venues/' + report + '/' + token + '/share_url_to_email', {
        email,
      });
  }
}();

