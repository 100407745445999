<template>
  <div class="content">
    <div class="container" style="max-width: 1300px;" v-loading="loading">
      <div class="logo">
        <img src="/img/brand/vc-logo-u.svg"/>
      </div>
      <div v-if="dataNotReady">
        <span class="alert alert-warning d-block my-5">Data is being generated - please wait.</span>
      </div>
      <div v-else>
        <div class="row mt-3">
          <div class="col-sm-12 perf-report-heading mb-3">
            <img src="/img/brand/logo-icon.svg" width="200px"/>
            <h2 class="h1">Performance Report</h2>
          </div>
          <div class="col-sm-12">
            <card v-loading="loading">
              <div class="row mt-0">
                <div class="col-sm-1 text-center text-sm-left">
                  <img :src="venue.thumbnail" class="avatar avatar-xl rounded-circle"/>
                </div>
                <div class="col-sm-9 text-center text-sm-left">
                  <h1>{{venue.name}}</h1>
                  <p class="small mb-0">{{venue_address}}</p>
                </div>
                <div class="col-sm-2 text-center">
                  <div class="row">
                    <div class="col" role='button' onclick="window.print();">
                      <i class="fa-light fa-print text-lg mt-3"></i><br/>
                      <span class="h6 display-block">Print</span>
                    </div>
                    <div class="col" role='button' @click="modalShareEmail=true">
                      <i class="fa-light fa-envelope-open-text text-lg mt-3"></i><br/>
                      <span class="h6 display-block">Share to Email</span>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <card class="bg-dark-color min-360" v-loading="loading">
              <div class="text-center">
                <img :src="venue_specialist.profile_photo" class="avatar avatar-xxl mt-5 rounded-circle mb-4"/>
                <h5 class="bold text-white mb-1">Venue Specialist</h5>
                <h4 class="text-white">{{venue_specialist.name}}</h4>
                <h5><a  class="email" :href="`mailto:` + venue_specialist.email"><i class="fa-light fa-envelope"></i>  {{venue_specialist.email}}</a></h5>
                <br/>
                <div>
                  <h5>
                    <span class="text-muted text-white text-xs"> Partner Since: {{venue_online_date | moment('DD/MM/Y')}}</span>
                  </h5>
                </div>
              </div>
            </card>
          </div>
          <div class="col-sm-8">
            <lead-chart :venueData="venueData" v-loading="loading"/>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-4">
            <Savings
              v-loading="loading"
              heading="Introductions"
              label="Personal recommendations or enquiries"
              color="#ff575b" :value="introductions"
              icon="fa-regular fa-handshake"></Savings>
          </div>
          <div class="col-sm-4">
            <Savings
              v-loading="loading"
              heading="Site Visits Booked" 
              label="Venue Crew scheduled site visits"
              color="#00989d" :value="venueData.site_visits_booked"
              icon="fa-regular fa-money-check-dollar"></Savings>
          </div>
          <div class="col-sm-4">
            <Savings
              v-loading="loading"
              heading="Sales" 
              label="Sales originating from an personal recommendations or enquiries"
              color="#fbb03b" :value="venueData.sales_won" 
              icon="fa-regular fa-hotel"></Savings>
          </div> 
        </div>
        <div class="row mt-2">
          <div class="col-sm-6">
            <recent-contacts/>
          </div>
          <div class="col-sm-6">
            <recent-site-visits/>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-12">
            <recent-sales :venueData="venueData" v-loading="loading"/>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-12">
            <card class="bg-dark-color" header-classes="bg-dark-color" v-loading="loading">
              <div slot="header" class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1"></h6>
                  <h5 class="h3 mb-0 text-white">Sales</h5>
                </div>
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1"></h6>
                  <h5 class="h5 mb-0 text-muted text-right">12 Months</h5>
                </div>
              </div>
              <div class="col-sm-12 ">
                <div class="row mt-2">
                  <div class="col-sm-4">
                    <card style="background:#ff575b">
                      <div>
                        <h2 class="ml-3 text-white">{{formatCurrency(venueData.total_revenue) || "-"}}</h2>
                        <h4 class="ml-3 text-white mb-0">Total Sales Value</h4>
                      </div>
                    </card>
                  </div>
                  <div class="col-sm-4">
                    <card style="background:#00989d">
                      <div>
                        <h2 class="ml-3 text-white">{{formatCurrency(venueData.total_commission) || "-"}}</h2>
                        <h4 class="ml-3 text-white mb-0">Total Commission</h4>
                      </div>
                    </card>
                  </div>
                  <div class="col-sm-4">
                    <card style="background: #fbb03b">
                      <div >
                        <h2 class="ml-3 text-white">{{venueData.total_sales || "-"}}</h2>
                        <h4 class="ml-3 text-white mb-0">Total Sales</h4>
                      </div>
                    </card>
                  </div>
                </div>
              </div>

              <bar-chart :height="350" ref="bigChart" :chart-data="totalRevenue" :extra-options="totalRevenueOptions">
              </bar-chart>
            </card>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <Savings
              v-loading="loading"
              heading="Total Savings" 
              label="Venue Crew generally saves more than the commission cost of your actualised sales"
              color="#ff575b" :value="formatCurrency(venueData.total_savings)" 
              icon="fa-regular fa-filter-circle-dollar"></Savings>
          </div>
          <div class="col-sm-4">
            <Savings
              v-loading="loading"
              heading="Marketing Spend Saving"
              label="The average enquiry to viewing converts at 26%"
              color="#00989d" :value="formatCurrency(venueData.marketing_spent_savings)"
              icon="fa-thin fa-chart-mixed"></Savings>
          </div>
          <div class="col-sm-4">
            <Savings
              v-loading="loading"
              heading="Staff Spend Saving"
              label="The average venue sales coordinator spends 45 minutes per enquiry (enquiry to sale)"
              color="#fbb03b" :value="formatCurrency(venueData.staff_spent_savings)" 
              icon="fa-thin fa-chart-user"></Savings>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <Pipeline :venueData="venueData" v-loading="loading"/>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-6">
            <List :table_data="venueData.top_venues_lost_to || []" heading="Venues Lost To" v-loading="loading" limit="5"/>
          </div>
          <div class="col-sm-6">
            <List :table_data="venueData.lost_reasons || []" heading="Reasons Lost" v-loading="loading"/>
          </div>
        </div>
        <div class="row align-items-center mt-5">
          <div class="col-sm-4">
          </div>
          <div class="col-sm-4 mb-5">
            <img src="/img/brand/logo-icon.svg" class="mt-5 footer-logo"/>
            <p class="footer-logo-text text-muted text-center   text-uppercase mt-5">Powered by Easy Weddings. The Wedding Venue experts.</p>
          </div>
          <div class="col-sm-4">
          </div>
        </div>
      </div>
    </div>
    <modal
      :show.sync="modalShareEmail"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-sm"
    >
      <div class="col-sm-12">
        <template>
          <card>
            <div slot="header" class="row align-items-center">
              <div class="col-sm-8">
                <h3 class="mb-0" id="venue_details_heading">Share to email</h3>
              </div>
            </div>
            <div id="email_input">
              <base-input
                type="email"
                required
                label="Email to Share"
                id="Email"
                placeholder="Email to Share"
                v-model="shareEmail"
              >
              </base-input>
            </div>
            <div class="row">
              <div class="col">
                <button class="btn btn-secondary w-100" @click="modalShareEmail=false">Cancel</button>
              </div>
              <div class="col">
                <button class="btn btn-primary w-100" @click="sendEmail">Send</button>
              </div>
            </div>
          </card>
        </template>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { Table, TableColumn } from "element-ui";
import BarChart from "@/components/Charts/BarChart";
import Savings from "@/views/Pages/Reports/VenueReportValues/Savings.vue";
import RecentContacts from "@/views/Pages/Reports/VenueReportValues/RecentContacts.vue";
import RecentSiteVisits from "@/views/Pages/Reports/VenueReportValues/RecentSiteVisits.vue";
import RecentSales from "@/views/Pages/Reports/VenueReportValues/RecentSales.vue";
import Pipeline from "@/views/Pages/Reports/VenueReportValues/Pipeline.vue";
import LeadChart from "@/views/Pages/Reports/VenueReportValues/LeadChart.vue";
import ReportPublicLinkService from "@/services/report_public_links.service";
import List from "@/views/Pages/Reports/VenueReportValues/List.vue";
import Chart from 'chart.js';
import moment from "moment";
import { formatCurrency as currencyFormat } from '@/util/formatCurrency';
export default {
  components: {
    BarChart,
    Savings,
    Pipeline,
    List,
    LeadChart,
    RecentContacts,
    RecentSiteVisits,
    RecentSales,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      loading: true,
      venueData: {},
      dataNotReady: true,
      modalShareEmail: false,
      shareEmail: '',
      totalRevenueOptions: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                lineWidth: 0,
              },
              ticks: {
                callback: (label, index, labels) =>{
                  return this.formatCurrency(label );
                },
              },
            },
          ],
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: ((tooltipItems, data) => {
              let label = data.datasets[tooltipItems.datasetIndex ].label;
              let yLabel = '';
              if (label === 'Sales' && tooltipItems.yLabel) {
                yLabel = new Intl.NumberFormat().format(tooltipItems.yLabel.toFixed(2));
              } else if (tooltipItems.yLabel){
                yLabel = this.formatCurrency(tooltipItems.yLabel);
              }
              
              return label + ': ' + yLabel;
            }),
          },
        },
      },
    };
  },
  async created() {
    document.title = "Venue Crew - Venue Performance Report";
    this.fetchData();
  },
  computed: {
    venue_address() {
      if (this.venue.address) {
        let {street_number, street_name, city, state, post_code, country } = this.venue.address;
        return (street_number ? `${street_number} ` : '')
        + (street_name ? `${street_name} ` : '')
        + (city ? `${city} ` : '')
        + (state ? `${state} ` : '')
        + (post_code ? `${post_code} ` : '')
        + (country ? `${country} ` : '');
      }
      return '';
    },
    venue_specialist() {
      return this.venueData.venue_specialist ? this.venueData.venue_specialist : {};
    },
    venue() {
      return this.venueData.venue ? this.venueData.venue : {};
    },
    totalRevenue() {
      let labels = [];
      let revenue = [];
      let commission = [];
      let sales = [];
      if (this.venueData.revenue) {
        for (const property in this.venueData.revenue) {
          labels.push(moment(new Date("01-" + property)).format('MMM').toUpperCase());
          revenue.push(this.venueData.revenue[property].revenue);
          commission.push(this.venueData.revenue[property].commission);
          sales.push(this.venueData.revenue[property].sales);
        }
      }
      this.fixLineChart();
      return {
        labels: labels,
        datasets: [
          {
            label: 'Revenue',
            data: revenue,
            order: 1,
          },
          {
            label: 'Commission',
            data: commission,
            order: 1,
          },
          {
            label: 'Sales',
            borderColor: '#FFA500',
            backgroundColor: '#FFA500',
            borderJoinStyle: 'miter',
            type: 'line',
            order: 0,
            borderWidth: 2,
            data: sales,
            pointLabelFontSize : 4,
            borderWidth: 2,
            fill: false,
            lineTension: .3,
          },
        ],
      };
    },
    venue_online_date() {
      return this.venue.venue_online_date ? this.venue.venue_online_date : this.venueData.venue_published_date;
    },
    introductions() {
      if (this.venueData.introductions) {
        return this.venueData.introductions;
      }
      return this.venueData.borchure_downloads + this.venueData.price_requests + this.venueData.viewings + this.venueData.recommendations;
    },
    upcoming_weddings () {
      let weddings = this.venueData.upcoming_weddings ? this.venueData.upcoming_weddings : [];
      let data = [];
      for (let row in weddings) {
        data.push(weddings[row].first_name + ' & ' + weddings[row].partner_first_name + ' ' + moment(weddings[row].event_date).format('DD/MM/YY') );
      }
      return data;
    },
  },
  methods: {
    async fetchData() {
      let self = this;
      let interval = window.setInterval(async function() {
        self.loading = true;
        let data =  await ReportPublicLinkService.getData(self.$route.query.report, self.$route.query.token);
        self.venueData = data.data;
        if (!self.venueData.venue) {
          self.dataNotReady = true;
        } else {
          self.dataNotReady = false;
          self.loading = false;
          clearInterval(interval);
        }
      }, 2000);
    },
    fixLineChart() {
      Chart.pluginService.register({
        afterUpdate: function(chart) {
        // We get the dataset and set the offset here
          var dataset = chart.config.data.datasets;
          var offset = -3;

          // // For every data in the dataset ...
          if (!dataset) {
            return;
          }
          for (var i = 0; i < dataset.length; i++) {
            if (dataset[i].label === 'Sales'  ) {
              let entries  = Object.entries(dataset[i]._meta);
              let property = entries[0][0];
              if (dataset[i]._meta[property].data.length) {
                for (var j = 0; j < dataset[i]._meta[property].data.length; j++) {
                  // We get the model linked to this data
                  var model = dataset[i]._meta[property].data[j]._model;
                  // And add the offset to the `x` property
                  let _offset = dataset[i].data[j] * offset;
                  model.y += _offset;

                  // .. and also to these two properties
                  // to make the bezier curve fits the new graph
                  model.controlPointNextY += _offset;
                  model.controlPointPreviousY += _offset;
                }
              }
            }
          }
          
        },
      });
    },
    formatCurrency(value) {
      if (! (this.venueData && this.venueData.venue) || ! value) {
        return value;
      }
      let country = this.venueData.venue.country.toLowerCase();
      return currencyFormat(country, value);
    },
    async sendEmail() {
      if (!this.shareEmail) {
        let element = document.querySelectorAll('input#Email')[0];
        element.focus();
        element.blur();
        return;
      }
      this.loading = true;
      await ReportPublicLinkService.shareUrlToEmail(this.$route.query.report, this.$route.query.token, this.shareEmail);
      this.loading = false;
      this.modalShareEmail = false;
    },
  },
};
</script>
<style>

body{
  font-family: 'Nunito Sans', sans-serif;
  background-color: #eef1f7;
}
@media(max-width:1400px) {
  .logo {
    display: none;
  }
}
.logo {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 65px;
  background: #fff;
}

.logo img {
  margin-top: 10px;
}

.bg-dark-color {
  background-color: #232340;
}

.min-360 {
  min-height: 360px
}

div.data-values {
    display: table;
    width: 100%;
}

div.data-values > div {
    display: table-row;
    width: 100%;
}
div.data-values > div > div {
    display: table-cell;
    padding-top: 8px;
    padding-bottom: 8px;
}
div.data-values > div > div:last-child {
  width: 13%;
}
img.footer-logo {
  width: 200px;
  margin: auto;
  display: block;

}
.footer-logo-text {
  font-size:.7rem !important
}
.inverted-dark-badge {
  background: #393953;
  padding: 4px 9px;
  font-size: 9px !important;
  border-radius: 10px;
  color: #1a8e85;
}
.dark-badge {
  background: #232340;
  padding: 4px 9px;
  font-size: 9px !important;
  border-radius: 10px;
  color: #0d9285;
}
.email {
  color: #0d9285;
} 
.modal-dialog .modal-content .modal-body .card {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.page-link {
  cursor: pointer;
}
.cell .text-muted {
  text-transform: capitalize;
}
@media (max-width:500px) {
  #venue_details_heading {
    margin-top: 20px;
    margin-bottom: 20px !important;
  }
}
@media (min-width:480px) {
  .perf-report-heading {
    display: flex;
    align-items: center;
  }
}
@media (max-width:480px) {
  .perf-report-heading {
    text-align: center;
  }
}
</style>