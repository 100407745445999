<template>
  <card class="paddingless">
    <div slot="header" class="row align-items-center">
      <div class="col">
        <h5 class="h3 mb-2 mt-3">{{heading}}</h5>
      </div>
    </div>

    <el-table
      class="table-responsive "
      :data="rows"
      :show-header="false"
    >

      <el-table-column label="Icons" min-width="170px" prop="status" sortable>
        <template v-slot="{ row }">
          <h5 class="text-muted">{{row.label}}</h5>
        </template>
      </el-table-column>
    </el-table>
  </card>
</template>
<script>
import { Table, TableColumn } from "element-ui";
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    table_data: {required: true},  
    heading: {requuired: true},    
    limit: {required: false},   
  },
  computed: {
    rows() {
      let data = [];
      let counter = 1;
      this.table_data.forEach(item => {
        if (this.limit && this.limit < counter) {
          return false;
        }
        data.push({
          label: item,
        });
        counter += 1;
      });
      return data;
    },
  },
};
</script>