<template>
  <card class="recent-contacts paddingless" v-loading="loading">
    <div slot="header" class="row align-items-center">
      <div class="col">
        <h5 class="h3 mb-2 mt-3">Introduction Date</h5>
      </div>
    </div>

    <el-table
      class="table-responsive "
      :data="items"
      size="mini"
    >
      <el-table-column label="" width="70px" prop="status">
        <template v-slot="{ row }">
          <div :class="icon(row.request_type) + ` field-icon ml-3`"></div>
        </template>
      </el-table-column>

      <el-table-column label="Name" min-width="170px" >
        <template v-slot="{ row }">
          <h5 class="text-muted">{{row.first_name + ' ' + row.last_name}}</h5>
        </template>
      </el-table-column>

      <el-table-column label="Activity"   >
        <template v-slot="{ row }">
          <h5 class="text-muted">{{row.activity_date | moment("DD/MM/YYYY")}}</h5>
        </template>
      </el-table-column>
    </el-table>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <base-pagination
        class="pagination-no-border mb-0"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="pagination.total"
      >
      </base-pagination>
    </div>
  </card>
</template>
<script>
import { Table, TableColumn } from "element-ui";
import tableMixin from '@/helpers/remoteTableMixin';
import Badge from '@/components/Badge.vue';
import ReportPublicLinkService from "@/services/report_public_links.service";
export default {
  mixins: [tableMixin],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    /*Badge,*/
  },
  data: function() {
    return {
      items: [],
      loading: true,
    };
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage(page) {
      this.loading = true;
      this.currentPageManuallyChanged = true;
      let recentContacts = await ReportPublicLinkService.paginateRecentContacts(this.$route.query.report, this.$route.query.token, page);
      this.items = recentContacts.data;
      this.pagination.total = parseInt(recentContacts.meta.total);
      this.pagination.perPage = parseInt(recentContacts.meta.per_page);
      this.loading = false;
    },
    icon(request_type) {
      if (request_type === 'Download brochure') {
        return "fa-regular fa-handshake";
      } else if (request_type === 'Request a viewing') {
        return "fa-light fa-hotel";
      } else if (request_type === 'Request a quote') {
        return "fa-light fa-sack-dollar";
      }
    },
    statusType(status) {
      if (status === 'won') {
        return "success";
      } else if (status === 'lost') {
        return "danger";
      } else if (status === 'scheduled') {
        return "primary";
      }
    },
  },
};
</script>
<style scoped> 
 .field-icon {
  font-size: 15px !important;
}

</style>
<style >
.el-table--mini td, .el-table--mini th {
  padding: 8px !important;
}
.badge {
  font-size: 85%;
  font-weight: bold;
}
</style>