<template>

  <div class="row d-none d-sm-block">
    <div class="col-12" >
      <div class="pipelines">
        <div class="header">
          <h5 class="h3 mb-0 text-white">Pipeline</h5>
        </div>
        <div class="row">
          <div class="col-12">
            <vue-funnel-graph
              :width="width" :height="height" :labels="labels"
              :values="values" :colors="colors"   :direction="direction"
              :gradient-direction="gradientDirection" 
              :animated="false" :display-percentage="false"
            ></vue-funnel-graph>
            <span class="site_visit_percent">{{site_visit_conversion_rate}}%</span>
            <span class="sale_percent">{{sale_conversion_rate}}50%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueFunnelGraph } from 'vue-funnel-graph-js';
export default {
  props: {
    venueData: {required: true},         
  },
  components: {
    VueFunnelGraph,
  },
  data() {
    return {
      labels: ['Introductions', 'Site Visits', 'Sales'],
      subLabels: ['Conversion Rate'],
      colors: [ 
        '#ff575b' , // color set for "Introductions" segment
        '#00989d' , // color set for "Site Visits" segment
        '#fbb03b' ,  // color set for "Sales" segment
      ],
      direction: 'horizontal',
      gradientDirection: 'horizontal',
      height: 250,
      width: 1270,
    };
  },
  computed: {
    introductions() {
      if (this.venueData.introductions) {
        return this.venueData.introductions;
      }
      return this.venueData.borchure_downloads + this.venueData.price_requests + this.venueData.viewings + this.venueData.recommendations;
    },
    values () {
      return [
        this.introductions , // Segments of "Introductions" from top to bottom
        this.venueData.site_visits_booked , // Segments of "Site Visits"
        this.venueData.sales_won, // Segments of "Sales"
      ]   
      ;
    },
    site_visit_conversion_rate () {
      return ((this.venueData.site_visits_booked / this.introductions) * 100).toFixed(1);
    },
    sale_conversion_rate () {
      return ((this.venueData.sales_won / this.venueData.site_visits_booked) * 100).toFixed(1);
    },
  },
};
</script>
<style>
.pipelines {
  background: #232340; 
  border-radius: 7px  ;
}
.paddingless .card-body {
  padding: 0;
}
.paddingless .field-icon {
  font-size: 27px;
}
.pipelines .header  {
  padding-top: 40px;;
  padding-bottom: 20px;
  padding-left: 35px;
}
.paddingless .table td .progress, .paddingless  .el-table td .progress {
  width: 160px;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title {
  color: white;
  padding-left: 10px;
  font-size: 0.9375rem;
  font-weight: 400;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label.label-1 .label__value {
  color: #ff575b;
  padding-left: 10px;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label.label-2 .label__value {
  color: #00989d;
  padding-left: 10px;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label.label-3 .label__value {
  color: #fbb03b;
  padding-left: 10px;
}
.site_visit_percent {
  padding-left: 10px;
  color: white;
  position: absolute;
  top: 50px;
  left: 39px;
  font-size: 17px;
}
.sale_percent {
  padding-left: 10px;
  color: white;
  position: absolute;
  top: 50px;
  left: 462px;
  font-size: 17px;
}
.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child) {
  border-left-color: #ffffff40;
}
</style>