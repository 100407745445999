<template>
  <card class="recent-contacts paddingless" v-loading="loading">
    <div slot="header" class="row align-items-center">
      <div class="col">
        <h5 class="h3 mb-2 mt-3">Recent Sales</h5>
      </div>
    </div>

    <el-table
      class="table-responsive "
      :data="items"
      size="mini"
    >

      <el-table-column label="" width="70px" prop="status">
        <template v-slot="{ row }">
          <div class="fa-light fa-sack-dollar field-icon ml-3"></div>
        </template>
      </el-table-column>

      <el-table-column label="Name" min-width="170px" >
        <template v-slot="{ row }">
          <h5 class="text-muted">{{row.first_name + ' ' + row.last_name}}</h5>
        </template>
      </el-table-column>
      <el-table-column label="Enquiry Date"  >
        <template v-slot="{ row }">
          <h5 class="text-muted">{{row.enquiry_date | moment("h:mm a DD/MM/YYYY")}}</h5>
        </template>
      </el-table-column>

      <el-table-column label="Event Date"  >
        <template v-slot="{ row }">
          <h5 class="text-muted">{{row.event_date | moment("DD/MM/YYYY")}}</h5>
        </template>
      </el-table-column>
      <el-table-column label="Guest Number"   >
        <template v-slot="{ row }">
          <h5 class="text-muted">{{row.guests}}</h5>
        </template>
      </el-table-column>
      <el-table-column label="Sale Value"   >
        <template v-slot="{ row }">
          <h5 class="text-muted">{{formatCurrency(row.sale_value)}}</h5>
        </template>
      </el-table-column>
      <el-table-column label="Arranged By"   >
        <template v-slot="{ row }">
          <h5 class="text-muted">{{row.rep_name}}</h5>
        </template>
      </el-table-column>
    </el-table>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <base-pagination
        class="pagination-no-border mb-0"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="pagination.total"
      >
      </base-pagination>
    </div>
  </card>
</template>
<script>
import { Table, TableColumn } from "element-ui";
import tableMixin from '@/helpers/remoteTableMixin';
import Badge from '@/components/Badge.vue';
import ReportPublicLinkService from "@/services/report_public_links.service";
import { formatCurrency as currencyFormat } from '@/util/formatCurrency';
export default {
  mixins: [tableMixin],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    venueData: {
      required: true,
    },
  },
  data: function() {
    return {
      items: [],
      loading: true,
    };
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage(page) {
      this.loading = true;
      this.currentPageManuallyChanged = true;
      let recentSales = await ReportPublicLinkService.paginateRecentSales(this.$route.query.report, this.$route.query.token, page);
      this.items = recentSales.data;
      this.pagination.total = parseInt(recentSales.meta.total);
      this.pagination.perPage = parseInt(recentSales.meta.per_page);
      this.loading = false;
    },
    icon(request_type) {
      if (request_type === 'Download brochure') {
        return "fa-regular fa-handshake";
      } else if (request_type === 'Request a viewing') {
        return "fa-light fa-hotel";
      } else if (request_type === 'Request a quote') {
        return "fa-light fa-sack-dollar";
      }
    },
    statusType(status) {
      if (status === 'won') {
        return "success";
      } else if (status === 'lost') {
        return "danger";
      } else if (status === 'scheduled') {
        return "primary";
      }
    },
    formatCurrency(value) {
      if (!(this.venueData && this.venueData.venue) || ! value) {
        return value;
      }
      let country = this.venueData.venue.country.toLowerCase();
      return currencyFormat(country, value);
    },
  },
};
</script>
<style scoped> 
 .field-icon {
  font-size: 15px !important;
}
.badge {
  font-size: 85%;
  font-weight: bold;
}
</style>