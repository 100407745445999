<template>
  <card style="overflow: hidden; height: 165px;">
    <div class="row">
      <div class="col-4">
        <div class="circle" :style="`background:` + color">
        </div>
        <div :class="`text-white icon ` + icon"></div>
      </div>
      <div class="col-8">
        <div class="circle-text">
          <h2 class="h2 ml-3 mb-0" :style="`color:` + color">{{value}}</h2>
          <h4 class="ml-3">{{heading}}</h4>
          <span class="ml-3 savings-label">{{label}}</span>
        </div>
      </div>
    </div>
  </card>
</template>
<script>
export default {
  props: {
    heading:{required: true},
    label:{required: false},
    color:{required: true},
    value:{required: true},
    icon:{required: true},       
  },
};
</script>
<style scoped>
.circle {
    border-radius: 150px;
    width: 250px;
    height: 165px;
    position: absolute;
    top: -24px;
    left: -110px;
    z-index: 1;
}
@media (max-width:500px) {
  .circle-text {
    margin-left: 15px;
  }
}
.icon {
    position: relative;
    z-index: 2;
    font-size: 42px;
    font-weight: lighter;
    margin-left: 14px;
    margin-top: 38px;
}
.savings-label {
  font-size: 12px;
  display: block;
}
</style>