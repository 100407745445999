<template>
  <card header-classes="bg-transparent overflow-hidden pr-0" class="min-360">
    <div slot="header" class="row align-items-center">
      <div class="col">
        <h6 class="text-uppercase text-muted ls-1"></h6>
        <h5 class="h3 mb-0">Contacts <span class="text-muted">12 Months</span></h5>
      </div>
      <div class="col text-right contacts">
        <span class="introduction_filter">
          <el-checkbox v-model="contacts_introductions"><span class="text-white">Introductions</span></el-checkbox> 
        </span>
        <span class="site_visit_filter">
          <el-checkbox v-model="contacts_site_visits"><span class="text-white">Site Visits</span></el-checkbox> 
        </span>
        <span class="sale_filter">
          <el-checkbox v-model="contacts_sales" ><span class="text-white">Sales</span></el-checkbox> 
        </span>
      </div>
    </div>
    <bar-chart
      :height="250" ref="bigChart" v-if="lead_graph_render" :chart-data="totalLeadCount"
      :extra-options="totalLeadCountOptions"></bar-chart>
  </card>
</template>
<script>
import { mapState, mapGetters } from "vuex"; 
import BarChart from "@/components/Charts/BarChart";
import { Checkbox } from "element-ui";
import moment from "moment";
export default {
  props: {
    venueData: {
      required: true,
    },
  },
  components: {
    BarChart, 
    [Checkbox.name]: Checkbox, 
  },
  data() {
    return {
      totalLeadCountOptions: {
        legend: {
          display: false,
        },
        scales: {
          display: false,
          yAxes: [
            {
              stacked: true,
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                zeroLineColor: "#dee2e6",
                drawBorder: false,
                drawTicks: false,
                lineWidth: 1,
                zeroLineWidth: 1,
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
              ticks: {
                beginAtZero: true,
                padding: 10,
                fontSize: 13,
                fontColor: "#8898aa",
                fontFamily: "Open Sans",
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              gridLines: {
                drawBorder: false,
                color: "transparent",
                zeroLineColor: "transparent",
              },
              ticks: {
                fontSize: 13,
                fontColor: "#8898aa",
                fontFamily: "Open Sans",
              },
            },
          ],
        },
        maintainAspectRatio: false,
        height: 250,
      },
      contacts_introductions: true,
      contacts_site_visits: true,
      contacts_sales: true,
      lead_graph_render: true,
    };
  },
  computed: {
    totalLeadCount() {
      let labels = [];
      let enquiries = [];
      let site_visits = [];
      let sales = [];

      if (this.venueData.lead_performance) {
        for (const property in this.venueData.lead_performance) {
          labels.push(moment(new Date("01-" + property)).format('MMM').toUpperCase());
          enquiries.push(this.venueData.lead_performance[property].enquiries);
          site_visits.push(this.venueData.lead_performance[property].site_visits);
          sales.push(this.venueData.lead_performance[property].sales);
        }
      } 
      let datasets = [];
      if (this.contacts_introductions) {
        datasets.push({
          label: 'Introductions',
          data: enquiries,
          backgroundColor: '#ff575b',
        });
      }
      if (this.contacts_site_visits) {
        datasets.push({
          label: 'Site Visits',
          data: site_visits,
          backgroundColor: '#00989d',
        });
      }

      if (this.contacts_sales) {
        datasets.push({
          label: 'Sales',
          data: sales,
          backgroundColor: '#fbb03b',
        });
      }

      return {
        labels: labels,
        datasets: datasets,
      };
    },
    
  },
  methods: {
    rerenderChart() {
      this.lead_graph_render = false;
      this.$nextTick(() => {
        this.lead_graph_render = true;
      });
    },
  },
  watch: {
    contacts_introductions() {
      this.rerenderChart();
    },
    contacts_site_visits() {
      this.rerenderChart();
    },
    contacts_sales() {
      this.rerenderChart();
    },
  },
};
</script>
<style>
 
.min-360 {
  min-height: 360px
} 
.card-header {
  padding-bottom: 9px;
}
.contacts .introduction_filter {
  background: #ff575b;
  width: 140px;
  padding: 25px 20px;
}
.contacts .site_visit_filter {
  background: #00989d;
  width: 140px;
  padding: 25px 20px;
}
.contacts .sale_filter {
  background: #fbb03b;
  width: 140px;
  padding: 25px 20px;
}
@media(max-width:1400px) {
  .card-header {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .contacts .el-checkbox {
    margin-bottom: 0;
  }
  .contacts .introduction_filter {
    display: inline-block;
    padding: 10px;
    width: auto;
  }
  .contacts .site_visit_filter {
    display: inline-block;
    padding: 10px;
    width: auto;
  }
  .contacts .sale_filter {
    display: inline-block;
    padding: 10px;
    width: auto;
  }
}
@media(max-width:500px) {
  .contacts .introduction_filter {
    display: block;
    text-align: left;
  }
  .contacts .site_visit_filter {
    display: block;
    text-align: left;
  }
  .contacts .sale_filter {
    display: block;
    text-align: left;
  }
}
</style>